<template>
  <validation-provider
    #default="validationContext"
    name="Year"
    rules="required"
  >
    <b-form-group
      label="Choose Year"
      label-for="year"
    >
      <v-select
        id="year"
        v-model="year"
        label="year"
        :options="yearOptions"
        :reduce="year=>year.year"
        @input="selectYear"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'SelectYear',
  components: {
    vSelect,
  },
  data() {
    return {
      year: '',
      yearOptions: [],
    }
  },
  created() {
    this.generateYears()
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    return {
      getValidationState,
    }
  },
  methods: {
    generateYears() {
      let currentYear = new Date().getFullYear()
      for (let startYear = 2020; currentYear >= startYear; currentYear -= 1) {
        this.yearOptions.push({ year: currentYear })
      }
    },
    selectYear(year) {
      this.$emit('update:year', year)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
