<template>
  <b-button
    class="btn-block"
    variant="primary"
    @click="refresh()"
  >
    Search
  </b-button>
</template>
<script>
export default {
  name: 'SearchButton',
  methods: {
    refresh() {
      this.$emit('refresh')
    },
  },
}
</script>
<style lang="scss">

</style>
