<template>
  <div>
    <b-row>
      <b-col>
        <div class="text-right mb-1">
          <b-button
            v-if="$can('addDonor','UsersController')"
            :to="{name:'add-donor'}"
            variant="primary"
          >
            Add Donor
          </b-button>
        </div>
        <b-card header="Search">
          <b-row>
            <b-col md="5">
              <search
                placeholder="Email, Phone"
                :search-query.sync="searchQuery"
                @refresh="getUserData();setUserInStore(searchQuery)"
              />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="getUserData();setUserInStore(searchQuery)" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header="Donor Info">
          <ul
            v-if="user.user_id"
            class="list-unstyled ml-2 text-capitalize"
          >
            <li><b>Name: </b>{{ user.name }}</li>
            <li><b>Email: </b>{{ user.email }}</li>
            <li><b>Phone: </b>{{ user.phone }}</li>
            <li><b>city: </b>{{ user.profile.city }}</li>
            <li><b>state: </b>{{ user.profile.state }}</li>
            <li><b>country: </b>{{ user.profile.country }}</li>
            <li><b>postal code: </b>{{ user.profile.postal_code }}</li>
            <li><b>street: </b>{{ user.profile.street }}</li>
            <li><b>latitude: </b>{{ user.profile.lat }}</li>
            <li><b>longitude: </b>{{ user.profile.lng }}</li>
          </ul>
          <b v-else>No user found</b>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-row align-h="end">
          <b-col md="2">
            <b-button
              v-if="$can('update','DonorController')"
              :to="{name:'edit-donor',params:{ id: user.user_id} }"
              :disabled="!user.user_id"
              variant="primary"
              class="btn-block mb-1"
            >
              Edit Donor
            </b-button>
          </b-col>

          <b-col md="2">
            <b-button
              v-if="$can('addDonationTransaction','TransactionController') && $can('getDonationMethods','TransactionController')"
              :to="{name:'add-donation',params:{ user_id: user.user_id} }"
              :disabled="!user.user_id"
              variant="primary"
              class="btn-block mb-1"
            >
              Add Donation
            </b-button>
          </b-col>

          <b-col
            v-if="$can('sendDonationToMail','TransactionController') && $can('getDonationsYearsForDonor','TransactionController')"
            md="2"
            class="mb-1"
          >
            <b-button
              v-b-modal.send-transactions-to-email
              :disabled="!user.user_id"
              variant="primary"
              class="btn-block"
            >
              Send Email
            </b-button>
          </b-col>

          <b-col
            v-if="$can('downloadTaxReceipt','TransactionController') && $can('getDonationsYearsForDonor','TransactionController')"
            md="2"
            class="mb-1"
          >
            <b-button
              v-b-modal.print-transactions
              :disabled="!user.user_id"
              variant="primary"
              class="btn-block"
            >
              Print
            </b-button>
          </b-col>
        </b-row>

        <b-button
          class="mb-1"
          variant="primary"
          :disabled="!isDonationsExist"
          @click="toggle"
        >
          {{ isDonationsSelected ? 'Deselect All' :'Select All' }}
        </b-button>

        <tax-receipt-table
          ref="parentTable"
          v-bind="{donations:user.donations}"
          :selected-donations.sync="selectedDonations"
        />
      </b-col>
    </b-row>

    <b-modal
      id="send-transactions-to-email"
      title="Send Email"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <send-transaction-to-email
        :user-id="user.user_id"
        :selected-donations="selectedDonations"
      />
    </b-modal>

    <b-modal
      id="print-transactions"
      title="Print"
      centered
      hide-footer
      no-close-on-backdrop
    >
      <print-transactions
        :user-id="user.user_id"
        :selected-donations="selectedDonations"
      />
    </b-modal>
  </div>
</template>
<script>
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import TaxReceiptTable from '@/common/components/Transactions/TaxReceiptTable.vue'
import SendTransactionToEmail from '@/common/components/Transactions/SendTransactionsToEmail.vue'
import PrintTransactions from '@/common/components/Transactions/PrintTransactions.vue'

export default {
  name: 'TaxReceipt',
  components: {
    Search,
    SearchButton,
    TaxReceiptTable,
    SendTransactionToEmail,
    PrintTransactions,
  },
  data() {
    return {
      user: { donations: [] },
      searchQuery: this.$store.getters['donor/getUser'],
      selectedDonations: [],
    }
  },
  computed: {
    isDonationsExist() {
      return this.user.donations.length > 0
    },
    isDonationsSelected() {
      return this.selectedDonations.length > 0
    },
  },
  created() {
    if (this.searchQuery) { this.getUserData() }
  },
  beforeDestroy() {
    this.removeUserIfLeavingDonation()
  },
  methods: {
    getUserData() {
      if (this.searchQuery.trim()) {
        this.$portalUsers.get('internalops/getUserDonationForEntity', {
          params: {
            key: this.searchQuery,
            entity_id: this.$store.getters['mainEntity/getEntityId'],
          },
        }).then(({ data }) => {
          this.user = data.data
        }).catch(() => {
          this.user = {}
        })
      }
    },
    setUserInStore(searchQuery) {
      this.$store.commit('donor/setUser', searchQuery)
    },
    removeUserIfLeavingDonation() {
      if (!['add-donor', 'add-donation'].includes(this.$route.name)) this.setUserInStore('')
    },
    toggle() {
      if (this.isDonationsSelected) {
        this.deselectAll()
      } else { this.selectAll() }
    },
    deselectAll() {
      this.selectedDonations = []
    },
    selectAll() {
      this.selectedDonations = this.user.donations.map(donation => donation.transaction_id)
    },
  },
}
</script>
<style lang="scss">
</style>
