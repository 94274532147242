<template>
  <b-form-group>
    <b-form-radio-group
      v-model="exportTypeProxy"
      :options="exportTypeOptions"
    />
  </b-form-group>
</template>
<script>
export default {
  name: 'ExportTransactionsType',
  props: {
    exportType: { type: String, default: '' },
  },
  data() {
    return {
      exportTypeOptions: [
        { text: 'By Selected Transactions', value: 'selected' },
        { text: 'By Years', value: 'years' },
      ],
    }
  },
  computed: {
    exportTypeProxy: {
      get() {
        return this.exportType
      },
      set(value) {
        this.$emit('update:exportType', value)
      },
    },
  },
}
</script>
<style lang="">

</style>
