<template>
  <b-input-group class="mb-1 mb-md-0">
    <b-form-input
      id="search"
      type="search"
      :placeholder="placeholder"
      @keyup.enter="searchEvent"
      @input="editSearchQuery"
    />
  </b-input-group>
</template>
<script>

export default {
  name: 'Search',
  props: {
    placeholder: { type: String, default: 'Name' },
  },
  methods: {
    searchEvent() {
      this.$emit('refresh')
    },
    editSearchQuery(value) {
      this.$emit('update:search-query', value)
    },
  },
}
</script>
<style lang='scss'>
</style>
