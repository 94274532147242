<template>
  <validation-observer v-slot="{handleSubmit}">
    <b-form @submit.prevent>
      <export-type :export-type.sync="exportType" />

      <select-years
        v-if="exportType === 'years'"
        :year.sync="year"
      />

      <submit-button
        :handle-submit="handleSubmit"
        :submit="sendToEmail"
        submit-text="Send"
      />
    </b-form>
  </validation-observer>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import SelectYears from './SelectYear.vue'
import ExportType from './ExportType.vue'

export default {
  name: 'SendTransactionsToEmail',
  components: { SelectYears, SubmitButton, ExportType },
  props: {
    userId: { type: Number, default: 0 },
    selectedDonations: { type: Array, default: () => [] },
  },
  data() {
    return {
      year: '',
      exportType: 'years',
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    return {
      successfulOperationAlert,
    }
  },
  methods: {
    sendToEmail() {
      return this.$payment.get('internalops/sendDonationToMail', {
        params: {
          user_id: this.userId,
          entity_id: this.$store.getters['mainEntity/getEntityId'],
          ...(this.exportType === 'years' ? { year: this.year } : { transactionsIDs: this.selectedDonations.toString() }),
        },
      }).then(() => {
        this.successfulOperationAlert('Donations is sent to email')
        this.$bvModal.hide('send-transactions-to-email')
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
