<template>
  <b-card>
    <b-form-checkbox-group v-model="selectedDonationsProxy">
      <b-table
        ref="table"
        :no-provider-sorting="true"
        :items="donations"
        :fields="columns"
        responsive
        empty-text="No Donations found"
        show-empty
      >
        <template #cell(selection)="{item}">
          <b-form-checkbox
            :value="item.transaction_id"
            class="mb-1"
          />
        </template>
      </b-table>
    </b-form-checkbox-group>
  </b-card>
</template>
<script>
export default {
  name: 'TaxReceiptTable',
  props: {
    donations: { type: Array, default: () => [] },
    selectedDonations: { type: Array, default: () => [] },
  },
  data() {
    return {
      columns: [
        { key: 'selection' },
        { key: 'transaction_id' },
        { key: 'total_amount', formatter: value => `${value.toFixed(2)} $` },
        { key: 'net_amount', formatter: value => `${value.toFixed(2)} $` },
        { key: 'donation_type' },
        { key: 'payment_method' },
        { key: 'note' },
        { key: 'created_at', label: 'Donation Date' },
      ],
    }
  },
  computed: {
    selectedDonationsProxy: {
      get() {
        return this.selectedDonations
      },
      set(value) {
        this.$emit('update:selectedDonations', value)
      },
    },
  },
}
</script>
<style lang="scss">

</style>
